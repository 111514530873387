import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default {
  async selectFormData (Vue, id) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addExact('idparte_novedad', id)
    const resp = await Vue.$api.call('parteNovedad.select', { filter: apiFilter })
    return resp.data.result.dataset[0]
  },
}
